<template>
  <div class="recharge">
    <!-- 导航栏 -->
    <Navbar :title="$t('newAdd.Recharge')">
      <template #right>
        <button @click="$router.push('/recharge-list')">
          {{ $t("newAdd.Record") }}
        </button>
      </template>
    </Navbar>
    <van-cell-group class="info_bar">
      <van-cell class="cell_item" :title="$t('newAdd.MemberAccount')" :value="account">
      </van-cell>
      <van-cell class="cell_item" :title="$t('My.MemberInformation.balance')"
        :value="`${$money(userInfo.money, false)}`">
        <!-- ≈ USDT${0} -->
      </van-cell>
    </van-cell-group>
    <!-- language-ch 充值金额 -->
    <!-- 允许输入数字，调起带符号的纯数字键盘 -->
    <div class="recharge_number_bar">
      <div class="title">{{ $t("newAdd.RechargeAmount") }}</div>
      <van-field center v-model="number" type="number" :placeholder="$t('newAdd.RechargeAmountPlaceholder')" />
    </div>
    <div class="grid">
      <div class="grid_item" v-for="value in countList" :key="value" @click="addClass(value)"
        :class="{ active: number === value }">
        <!-- {{ `${verConfig.moneyType}${value}` }} -->
        {{ value }}
      </div>
    </div>
    <!-- <div class="selectCurrency" v-if="verConfig.rechargeSelectCurrency">
      <div class="title">{{ $t("recharge.selectCurrency.title") }}</div>
      <div class="currency_content">
        <div class="currency_item" v-for="item, index in verConfig.rechargeSelectCurrency"
          :class="{ active: item === currentCurrency }" @click="currentCurrency = item">{{ item }}</div>
      </div>
    </div> -->
    <div class="recharge_type">
      <!-- <div class="title">{{ $t("newAdd.SelectRechargeMethod") }}</div> -->
      <van-tabs v-model="active" swipeable type="card" class="tabs" line-height="2px" line-width="15px">
        <van-tab v-for="item in tabPages.filter(i => !!i.lists.length)" :key="item.type">
          <span slot="title">{{ item.title }}</span>
          <div v-if="item.lists.length > 0">
            <div class="mode" v-for="value in item.lists" :key="value.id" @click="paySubmit(value)">
              <img v-if="item.type !== 'idpay'" class="step-image"
                :src="require(`@/assets/images/user_pay/${item.type}.png`)" />
              <div class="step">
                <div class="title_bar">
                  <div class="title">{{ value.title }}</div>
                  <div class="value">
                    {{ $t("recharge.enter") }}
                    <van-icon name="arrow" />
                  </div>
                </div>
                <div>
                  <div class="item" v-for="(item, index) in (value.desc || '').split('\n')" :key="`item_${index}`">
                    {{ item }}
                  </div>
                </div>
              </div>
              <img v-if="value.letter == 1" class="tabbar-letter"
                :src="require('@/assets/images/user_pay/letter.png')" />
            </div>
          </div>
          <div v-else class="tips">
            {{ $t("newAdd.HaveNoteMethod") }}
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- <van-cell title="单元格">
      <span slot="title" class="list-title-text">
        {{ $t("newAdd.SelectRechargeMethod") }}
      </span>
    </van-cell> -->

    <!-- <div class="tutorial" v-show="showTutorial">
      <img
        :src="require('@/assets/images/user_pay/step/' + addCount + '.jpg')"
        @click="changeImg"
      />
    </div> -->

    <!-- 标签页 支付方式-->

    <!-- <Tabbar active="new-recharge" /> -->
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
} from "@/api";
import Cookies from "js-cookie";
import { getUserInfo } from "@/utils/tools";
import Tabbar from "@/components/Tabbar";
import verConfig from "@/utils/verConfig";
import {
  NavBar,
  Cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
  CellGroup,
} from "vant";
import Navbar from "@/components/JNav";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    Tabbar,
    Navbar,
  },
  data() {
    return {
      verConfig,
      // countList: ["20", "50", "100", "200", "500", "1000", "3000", "5000"],
      countList: ["200", "500", "1000", "3000", "10000", "20000","50000","80000"],
      account: "",
      number: "",
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: "",
      country: Cookies.get("language"),
      userInfo: {},
      currentCurrency: ""
    };
  },
  created() {
    getUserInfoNew();
    this.getRechargeList();
    // this.getLists()
    // this.getService()
    // console.log(Cookies.get('language'));
  },
  mounted() {
    this.account = getUserInfo().userphone;
    this.userInfo = getUserInfo();
  },
  methods: {
    // add
    goback() {
      history.back();
    },
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LIST()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            this.tabPages = r.data.data;
          }
        })
        .catch((e) => {
          Toast.clear();
        });
    },
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      this.number = value;
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret },
      } = await request({
        method: "get",
        url: "pay/lists",
      });
      if (ret === 1) {
        this.tabPages = data;
      }
    },

    // async getService () {
    //   const { data: { data, ret } } = await request({
    //     method: 'get',
    //     url: 'other/config',
    //     params: {
    //       act: 'system',
    //       refresh: this.isLoading === true ? 1 : 0
    //     }
    //   })
    //   if (ret === 1) {
    //     this.serviceURL = data.service_url
    //   }
    // },
    // 教程
    changeImg() {
      this.count += 1;
      if (this.count === 6) {
        this.showTutorial = false;
        this.count = 1;
      }
    },

    handlerService() {
      //   Toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL;
    },

    paySubmit(item) {
      // console.log(item);
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT({
        id: item.id,
        money: this.number,
        account: this.account,
        // isbefore: 1,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 2) {
            Toast.clear();
            window.location.href = r.data.data.url;
          } else if (r.data.ret === 1) {
            if (r.data.data.bank.indexOf("qrcode") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "qrPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else if (r.data.ret === 3) {
            if (r.data.data.bank.indexOf("bank-BANK") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "bankPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  computed: {
    addCount() {
      return this.count;
    },
  },
};
</script>

<style lang="less" scoped>
// @Color: #e23d6d;
@Color: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: #07304a;
@comBG: var(--bg1);

.recharge {
  position: relative;
  font-family: "PingFang-Regular";
  // height: 100%;
  min-height: 100vh;
  background: var(--hallBg);

  .van-nav-bar {
    background-color: var(--moduleColor);

    /deep/ .van-nav-bar__content {
      background-color: var(--moduleColor);
    }

    /deep/.van-nav-bar__title {
      color: var(--textColor);
    }

    button {
      background-color: var(--bg);
      border: none;
      color: var(--textColor);
      font-size: calc(14rem / 16);
      padding: 6px 12px;
      border-radius: 4px;
    }

    .iconfont {
      font-size: calc(22rem / 16);
      color: var(--textColor);
    }
  }

  .info_bar {
    margin: @wrapPadding;
    border-radius: @comRadius;
    background: var(--moduleColor);

    /deep/ .van-cell__title {
      color: var(--gray3);
    }

    /deep/ .van-cell__value {
      color: var(--gold);
    }

    &::after {
      border: none;
    }

    .cell_item {
      &::after {
        border-color: #124b55;
      }
    }
  }

  .recharge_number_bar {
    margin: @comRadius;
    margin-top: calc(26rem / 16);

    .title {
      text-indent: 0.6em;
      color: var(--gray3);
      font-size: calc(15rem / 16);
    }

    /deep/ .van-field {
      background: var(--moduleColor);
      border-radius: @comRadius;
      margin-top: calc(6rem / 16);

      input::placeholder {
        color: var(--gray2);
        opacity: 0.6;
      }

      input {
        color: var(--gold);
      }
    }
  }

  .grid {
    margin: @wrapPadding;
    @gap: 6px;
    display: flex;
    flex-wrap: wrap;

    .grid_item {
      width: calc((100% - @gap * 3) / 4 - 2px);
      text-align: center;
      color: var(--theme);
      border: 1px solid var(--theme);
      margin-left: @gap;
      margin-bottom: @wrapPadding;
      border-radius: 6px;
      line-height: calc(40rem / 16);

      &:nth-child(1) {
        margin-left: 0;
      }

      &:nth-child(4n + 1) {
        margin-left: 0;
      }
    }

    .active {
      color: var(--light);
      background-color: var(--theme);
    }
  }

  .selectCurrency {
    margin: @wrapPadding;

    .title {
      color: var(--gray1);
      text-indent: .6em;
    }

    .currency_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @gap: 6px;

      .currency_item {
        margin-top: @gap * 2;
        border: 1px solid var(--theme);
        border-radius: 6px;
        padding: 6px 18px;
        color: var(--theme);
      }

      .active {
        background-color: var(--theme);
        color: var(--light);
      }
    }
  }

  .recharge_type {
    .recharge_number_bar();

    /deep/ .van-tabs__wrap {
      @height: calc(50rem / 16);
      height: @height;
      margin-top: 6px;
      display: none;

      .van-tabs__nav,
      .van-tabs__nav--card {
        background: var(--bg);
        margin: 0;
        border: none;
        height: @height;

        .van-tab {
          margin-left: @wrapPadding;
          width: calc((100% - @wrapPadding) / 2);
          background: #2a68e8;
          color: var(--textColor);
          border: none;
          border-radius: @comRadius;

          &:nth-child(1) {
            margin-left: 0;
          }
        }

        .van-tab--active {
          background: #0094ac;
          border: none;
          color: var(--textColor);
        }
      }
    }
  }

  .van-cell {
    background-color: transparent;
  }

  .list-title-text {
    font-size: calc(13rem / 16);
    color: --gray2;
  }

  .list-title-rtext {
    color: #a87cf3;
    font-size: calc(13rem / 16);
    text-decoration: underline;
  }

  .tabs {

    // background: @comBG;
    .mode {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      margin-top: calc(10rem / 16);
      padding: calc(7.5rem / 16);
      // background-color: #fff;
      background: @comBG;
      border-radius: @comRadius;
    }

    .step {
      font-size: calc(12rem / 16);
      color: --gray2;
      flex-grow: 1;

      .title_bar {
        margin: 0 0 calc(7.5rem / 16) 0;
        display: flex;
        justify-content: space-between;

        .title {
          text-indent: 0;
          color: #fff7b7;
        }

        .value {
          color: var(--gray1);
          opacity: 0.5;
        }
      }
    }

    .tabbar-letter {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(32.5rem / 16);
      height: calc(32.5rem / 16);
    }

    .step-image {
      width: calc(50rem / 16);
      height: calc(50rem / 16);
      margin-right: calc(10rem / 16);
    }

    .tips {
      text-align: center;
      margin-top: calc(45rem / 16);
      font-size: calc(10rem / 16);
    }
  }

  .tutorial {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.backimg {
  width: 20px;
  height: 20px;
}
</style>
